import { Fragment, useEffect, useRef, useState } from "react";
import { FaPen } from "react-icons/fa";
import HowToUse from "../../../../common/utils/components/HowToUse";
import {
  getFormattedPrice,
  setToAdminTransAcc,
} from "../../../../common/utils/FunctionUtility";
import { useComponentsContext } from "../../../ContextProvider";

import { AccmlPolicyModifyModal } from "./modal";

const AccumulationPolicyMng = () => {
  const { setLoading, setUserRestStep, setUserRequestDatas } =
    useComponentsContext();
  const policyRef = useRef({});

  const [accmlList, setAccmlList] = useState([]);
  const [isShowAccmlModal, setIsShowAccmlModal] = useState(false);

  const [selectedAccmlPolicy, setSelectedAccmlPolicy] = useState({});

  // 등급 리스트 조회
  const fetchData = () => {
    setLoading(true);
    fetch(`/api/point/policy/earn/list?st_code=${setToAdminTransAcc().stCode}`)
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.result) {
          res.data.map((data) => {
            const formatData = [
              "card_crt_amt",
              "card_crt_unit",
              "cash_crt_amt",
              "cash_crt_unit",
            ];
            formatData.map((format) => {
              data[format] = getFormattedPrice(data[format]);
            });
          });
          setAccmlList(res.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  // 적립 정책 수정 버튼 클릭
  const handleModalOpen = (gd_level) => {
    const currentRefID = policyRef.current[gd_level].id;
    const getList = accmlList.find(
      (accml) => accml.gd_level === Number(currentRefID)
    );
    setSelectedAccmlPolicy(getList);
    setIsShowAccmlModal(true);
  };

  // 적립 정책 사용 여부
  const handleTogglePolicyStatus = (e, gd_level) => {
    setUserRestStep({ key: 30, control: "update/status" });
    setUserRequestDatas({
      st_code: setToAdminTransAcc().stCode,
      gd_level: gd_level,
      is_active: JSON.parse(e.currentTarget.value),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Fragment>
      <HowToUse currentMenu={"accmlPolicy"} cardWidth={"full"} />
      <div className="col-lg-12 pl-0 pr-0">
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <div className="table-stats order-table ov-h c-table">
                <div className="d-flex justify-content-sm-between flex-column">
                  <table className="table text-center fontS add-table accml-table">
                    <thead>
                      <tr>
                        <th style={{ width: "60px" }} rowSpan={"2"}>
                          No
                        </th>
                        <th rowSpan={"2"}>적용등급</th>
                        <th colSpan={"2"}>카드결제</th>
                        <th colSpan={"2"}>현금결제</th>
                        <th style={{ width: "110px" }} rowSpan={"2"}>
                          사용여부
                        </th>
                        <th style={{ width: "200px" }} rowSpan={"2"}>
                          적용일자
                        </th>
                        <th rowSpan={"2"}>관리</th>
                      </tr>
                      <tr>
                        <th>기준금액</th>
                        <th>적립단위</th>
                        <th>기준금액</th>
                        <th>적립단위</th>
                      </tr>
                    </thead>
                    <tbody>
                      {accmlList &&
                        accmlList.map((table) => {
                          return (
                            <tr
                              key={table.gd_level}
                              id={table.gd_level}
                              ref={(el) =>
                                (policyRef.current[table.gd_level] = el)
                              }
                            >
                              <td>{table.gd_level}</td>
                              <td>{table.gd_name} 이상</td>
                              <td>{table.card_crt_amt} 원</td>
                              <td>
                                {table.card_crt_unit.includes(".")
                                  ? table.card_crt_unit.replaceAll(",", "")
                                  : table.card_crt_unit}{" "}
                                {table.card_criteria}
                              </td>
                              <td>{table.cash_crt_amt} 원</td>
                              <td>
                                {table.cash_crt_unit.includes(".")
                                  ? table.cash_crt_unit.replaceAll(",", "")
                                  : table.cash_crt_unit}{" "}
                                {table.cash_criteria}
                              </td>
                              <td>
                                <select
                                  className="form-control"
                                  defaultValue={table.is_active}
                                  onChange={(e) =>
                                    handleTogglePolicyStatus(e, table.gd_level)
                                  }
                                  disabled={table.gd_level === 1 ? true : false}
                                >
                                  <option value={true}>예</option>
                                  <option value={false}>아니오</option>
                                </select>
                              </td>
                              <td>{table.reg_dt}</td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() => handleModalOpen(table.gd_level)}
                              >
                                <FaPen />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AccmlPolicyModifyModal
        isShowAccmlModal={isShowAccmlModal}
        setIsShowAccmlModal={setIsShowAccmlModal}
        selectedAccmlPolicy={selectedAccmlPolicy}
        setSelectedAccmlPolicy={setSelectedAccmlPolicy}
      />
    </Fragment>
  );
};

export default AccumulationPolicyMng;
